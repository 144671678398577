
import { Vue, Component } from "vue-property-decorator";
import { loginService } from "@api/mine/login/index.api";
import { getAppType } from "@/utils/cookies";

@Component({
  name: "SetUrl",
  components: {},
})
export default class Bywexin extends Vue {
  async getPromise() {
    let {
      data: { ReturnJson },
    } = await loginService({
      Action: "GetWeChatLoginUrl",
      AppType: getAppType(),
    });
    window.location.href = JSON.parse(ReturnJson);
  }
  created() {
    this.getPromise();
  }
}
